<template>
  <div>
    <BaseRibbonBanner
      v-if="displayEnvironmentRibbonBanner"
      size="small"
      type="danger"
      position="top-left"
      class="!fixed z-50 hidden md:block"
    >
      {{ useEnvironment() || "Unknown" }}
    </BaseRibbonBanner>
    <div class="flex h-full flex-col">
      <Disclosure
        as="nav"
        class="sticky top-0 z-20 h-full bg-white shadow-sm"
        v-slot="{ open, close }"
      >
        <div
          v-if="open"
          class="fixed inset-0 z-20"
          :class="{
            'bg-gray-400/75 backdrop-blur-sm': open,
          }"
        ></div>

        <div
          class="sticky top-0 z-20 h-full bg-white shadow-sm"
          v-on-click-outside="close"
        >
          <!-- Large screen version -->
          <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <div class="flex h-16 justify-between">
              <div class="flex">
                <div
                  class="flex flex-shrink-0 select-none flex-row items-center gap-4"
                >
                  <NuxtLink to="/">
                    <img
                      class="h-8 w-auto"
                      src="/logo.svg"
                      alt="Aerospacelab"
                    />
                  </NuxtLink>
                  <BaseChip
                    v-if="displayEnvironmentRibbonBanner"
                    color="red"
                    class="uppercase md:hidden"
                  >
                    {{ useEnvironment() || "Unknown" }}
                  </BaseChip>
                </div>
                <div
                  class="hidden md:-my-px md:ml-6 md:flex md:items-center md:space-x-1 lg:space-x-4"
                >
                  <NavLink
                    v-for="item in navigation"
                    :key="item.name"
                    :link="item.link"
                    :active="isActive(item.link)"
                    :data-text="item.name"
                  >
                    {{ item.name }}
                  </NavLink>
                </div>
              </div>
              <div
                class="hidden md:ml-6 md:flex md:min-w-0 md:flex-row md:items-center"
              >
                <NotificationPanel />
                <!-- Profile dropdown -->
                <Menu v-if="profile" as="div" class="relative ml-3 min-w-0">
                  <div class="flex min-w-0">
                    <MenuButton
                      class="flex min-w-0 flex-row gap-4 rounded-full pr-4 text-sm"
                    >
                      <img
                        class="h-8 w-8 rounded-full"
                        src="/profile.png"
                        alt="Profile picture"
                      />
                      <div class="flex min-w-0 flex-col text-left">
                        <span
                          class="truncate text-xs font-medium text-gray-900"
                        >
                          {{ profile.firstName }}
                          {{ profile.lastName }}
                        </span>
                        <span class="truncate text-xs text-gray-500">
                          {{ profile.email }}
                        </span>
                      </div>
                    </MenuButton>
                  </div>
                  <transition
                    enter-active-class="transition ease-out duration-200"
                    enter-from-class="transform opacity-0 scale-95"
                    enter-to-class="transform opacity-100 scale-100"
                    leave-active-class="transition ease-in duration-75"
                    leave-from-class="transform opacity-100 scale-100"
                    leave-to-class="transform opacity-0 scale-95"
                  >
                    <MenuItems
                      class="absolute right-0 z-20 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5"
                    >
                      <MenuItem v-slot="{ active, close }">
                        <NuxtLink
                          to="/about_us"
                          :class="[
                            active ? 'bg-gray-100' : '',
                            'flex items-center gap-2 px-4 py-2 text-sm text-gray-700 hover:cursor-pointer',
                          ]"
                          @mouseup="close"
                        >
                          <BaseIcon class="h-4 w-4" name="Info"></BaseIcon>
                          About us
                        </NuxtLink>
                      </MenuItem>
                      <MenuItem v-slot="{ active }" @click="sendFeedback">
                        <a
                          :class="[
                            active ? 'bg-gray-100' : '',
                            'flex items-center gap-2 px-4 py-2 text-sm text-gray-700 hover:cursor-pointer',
                          ]"
                        >
                          <BaseIcon class="h-4 w-4" name="Send"></BaseIcon>
                          Send us feedback
                        </a>
                      </MenuItem>
                      <hr />
                      <MenuItem v-slot="{ active, close }">
                        <NuxtLink
                          to="/profile"
                          :class="[
                            active ? 'bg-gray-100' : '',
                            'flex items-center gap-2 px-4 py-2 text-sm text-gray-700 hover:cursor-pointer',
                          ]"
                          @mouseup="close"
                        >
                          <BaseIcon class="h-4 w-4" name="User"></BaseIcon>
                          Your profile
                        </NuxtLink>
                      </MenuItem>
                      <MenuItem v-slot="{ active }" @click="logout">
                        <a
                          :class="[
                            active ? 'bg-gray-100' : '',
                            'flex items-center gap-2 px-4 py-2 text-sm text-gray-700 hover:cursor-pointer',
                          ]"
                        >
                          <BaseIcon class="h-4 w-4" name="LogOut"></BaseIcon>
                          Sign out
                        </a>
                      </MenuItem>
                      <MenuItem v-if="versionNumber">
                        <p
                          class="flex items-center gap-2 px-4 py-2 text-sm text-gray-500"
                        >
                          Version: {{ versionNumber }}
                        </p>
                      </MenuItem>
                    </MenuItems>
                  </transition>
                </Menu>
              </div>
              <div class="-mr-2 flex items-center md:hidden">
                <!-- Mobile menu button -->
                <DisclosureButton
                  class="inline-flex cursor-pointer items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500"
                  as="div"
                >
                  <BaseIcon name="Menu" v-if="!open" class="block h-6 w-6" />
                  <BaseIcon name="X" v-else class="block h-6 w-6" />
                </DisclosureButton>
              </div>
            </div>
          </div>
          <!-- Mobile version -->
          <DisclosurePanel class="bg-white md:hidden">
            <div class="space-y-1 pb-3 pt-2">
              <NavLink
                v-for="item in navigation"
                :key="item.name"
                :link="item.link"
                :active="isActive(item.link)"
                @click="close"
              >
                {{ item.name }}
              </NavLink>
            </div>
            <div v-if="profile" class="border-t border-gray-200 pb-3 pt-4">
              <div class="flex items-center px-4">
                <div class="flex-shrink-0">
                  <img
                    class="h-10 w-10 rounded-full"
                    src="/profile.png"
                    alt="Profile picture"
                  />
                </div>
                <div class="ml-3 flex min-w-0 flex-col text-left">
                  <span class="truncate text-xs font-medium text-gray-900">
                    {{ profile.firstName }}
                    {{ profile.lastName }}
                  </span>
                  <span class="truncate text-xs font-medium text-gray-500">
                    {{ profile.email }}
                  </span>
                </div>
                <NotificationPanel class="ml-auto flex-shrink-0">
                </NotificationPanel>
              </div>
              <div class="mt-3 space-y-0">
                <NavLink link="/about_us" @click="close">
                  <span class="text-sm">About us</span>
                </NavLink>
                <NavButton
                  @click="
                    () => {
                      close();
                      sendFeedback();
                    }
                  "
                  class="text-sm"
                >
                  Send us feedback
                </NavButton>
                <NavLink
                  link="/profile"
                  :active="isActive('profile')"
                  @click="close"
                >
                  <span class="text-sm">Your profile</span>
                </NavLink>
                <NavButton @click="logout" class="text-sm">
                  Sign out
                </NavButton>
                <div
                  v-if="versionNumber"
                  class="block !py-2 px-4 text-sm text-gray-500"
                >
                  <p>Version: {{ versionNumber }}</p>
                </div>
              </div>
            </div>
          </DisclosurePanel>
        </div>
      </Disclosure>

      <div class="h-full">
        <NuxtPage></NuxtPage>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
} from "@headlessui/vue";
import { vOnClickOutside } from "@vueuse/components";
import { storeToRefs } from "pinia";
import useEnvironment from "~/composables/use-environment";
import { Environment } from "~/models";

const route = useRoute();
const authStore = useAuthStore();
const { logout } = authStore;
const { profile } = storeToRefs(authStore);
const {
  public: { versionNumber },
} = useRuntimeConfig();

const isActive = (link: string) =>
  !!route.name?.toString().includes(link.substring(1));

const sendFeedback = () => {
  window.open(
    "https://www.aerospacelab.com/web#menu_id=813&action=1465&active_id=15&model=helpdesk.ticket&view_type=form",
  );
};

const navigation = [
  { name: "Targets", link: "/targets" },
  { name: "Satellites", link: "/satellites" },
  { name: "Payloads", link: "/payload_configs" },
  { name: "Tasks", link: "/tasks" },
  { name: "Acquisitions", link: "/acquisitions" },
  { name: "Live", link: "/live" },
];

const displayEnvironmentRibbonBanner = computed(
  () => useEnvironment() !== Environment.PROD,
);
</script>
