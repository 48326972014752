<template>
  <div
    :class="[
      sizeStyle,
      positionStyleWrapper,
      'pointer-events-none absolute aspect-square overflow-hidden rounded-sm',
    ]"
  >
    <span
      :class="[
        'absolute block w-[141.42%] select-none py-1.5 text-center text-xs font-semibold uppercase tracking-wider shadow-sm',
        positionStyle,
        bgStyle,
        textStyle,
      ]"
    >
      <slot></slot>
    </span>
  </div>
</template>

<script setup lang="ts">
export interface Props {
  position?: "top-left" | "top-right" | "bottom-left" | "bottom-right";
  size?: "small" | "medium" | "large";
  type?: "normal" | "info" | "danger" | "warning";
}

const props = withDefaults(defineProps<Props>(), {
  position: "top-left",
  size: "medium",
  type: "normal",
});

const positionStyleWrapper = computed(() => {
  return {
    "top-0 left-0": props.position === "top-left",
    "top-0 right-0": props.position === "top-right",
    "bottom-0 left-0": props.position === "bottom-left",
    "bottom-0 right-0": props.position === "bottom-right",
  };
});

const positionStyle = computed(() => {
  return {
    "bottom-0 left-0 -rotate-45 origin-bottom-left":
      props.position === "top-left",
    "bottom-0 right-0 rotate-45 origin-bottom-right":
      props.position === "top-right",
    "top-0 left-0 rotate-45 origin-top-left": props.position === "bottom-left",
    "top-0 right-0 -rotate-45 origin-top-right":
      props.position === "bottom-right",
  };
});

const sizeStyle = computed(() => {
  return {
    "w-24": props.size === "small",
    "w-28": props.size === "medium",
    "w-36": props.size === "large",
  };
});

const bgStyle = computed(() => {
  return {
    "bg-gray-100": props.type === "normal",
    "bg-blue-100": props.type === "info",
    "bg-red-100": props.type === "danger",
    "bg-yellow-100": props.type === "warning",
  };
});

const textStyle = computed(() => {
  return {
    "text-gray-900": props.type === "normal",
    "text-blue-600 ": props.type === "info",
    "text-red-600": props.type === "danger",
    "text-yellow-700": props.type === "warning",
  };
});
</script>
